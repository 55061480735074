.DateRangePicker {
  .date-value {
    width: 100%;
    height: 38px;
    padding: 6px;
    border-color: #cccccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

  .datetime-range-picker-popup {
    padding: 7px;
  }

  .datetime-range-picker .calendar table thead th {
    color: #d87548;
  }

  .datetime-range-picker .calendar table tbody tr td.selected {
    background-color: #611b79db;
  }

  .datetime-range-picker .calendar table tbody tr td.now {
    color: #611b79db;

    &.selected {
      color: #ffff;
    }
  }

  .tools-bar {
    padding: 10px;

    .buttons {
      button:first-of-type {
        color: #007bff;
        border-color: #007bff;

        &:hover {
          color: #fff;
          background-color: #007bff;
          border-color: #007bff;
        }
      }
    }

    .span.text-label::after {
      content: "";
      display: inline-block;
      margin-right: 5px;
    }
  }
}
