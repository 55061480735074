#AssignKeyToProductProfile {
  & .react-bootstrap-table {
    max-height: 300px;
    overflow-y: auto !important;
  }

  #assign-keys-to-product-profile-table table {
    & > thead {
      position: sticky;
      background-color: white;
      top: 0;
      margin: 0 0 0 0;
    }

    & tr.duplicated-key * {
      color: #929292 !important;
    }
  }
}
