.plainTextSpan {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 20px;
  vertical-align: middle;

  span {
    position: absolute;
    right: 5px;
    top: 0;
  }
}
