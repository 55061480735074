.FilterBar {
  
    .filter-button {
      height: 100%;
    }
    
    .ButtonDropdown {
      width: 100%;
      height: 100%;
  
      .btn-group { 
        width: 100%;
        height: 100%;
      }
    
      button,button:disabled,
      button[disabled] {
        height: 100%;
        width: 100%;
      }
    }
  }
  