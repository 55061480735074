.serverSideSelectContainer {
  position: relative;
  width: 100%;

  .input-group {
    ::selection {
      background-color: transparent;
    }

    ::-moz-selection {
      background-color: transparent;
    }

    &:hover {
      .input, .inputIcon {
        border-color: hsl(0, 0%, 70%);
      }
    }
  }

  .input {
    width: 100% !important;
    background-color: transparent !important;
    cursor: pointer;
    padding-right: 50px;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .inputIcon {
    font-size: 14px !important;
    color: #cccccc;
    border-left: none;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 12px;
    right: 0;
    top: 0;
    height: 100%;
    text-align: center;
    justify-content: center;

    &:before {
      display: block;
      width: 0px;
      left: 0;
      top: 8px;
      bottom: 8px;
      height: calc(100% - 16px);
      position: absolute;
      content: "";
      border-right: 1px hsl(0, 0%, 80%) solid;
    }
  }

  &.open {
    .serverSideSelectGroupContainer {
      .input {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      .inputIcon {
        border-bottom-right-radius: 0;
      }
    }
  }

  .serverSideSelectGroupContainer {
    .serverSideSelectInputGroup {
      cursor: pointer;
      position: relative;

      ::selection {
        background-color: transparent;
      }

      &.hasError {
        border: 2px solid red;
        box-sizing: border-box;
        border-radius: 5px;
      }

      .form-control.is-invalid {
        background-position: right calc(2.375em + .1875rem) center;
      }
    }

    .inputIcon {
      background-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    span.text-danger {
      position: absolute;
    }
  }

  &.disabled .serverSideSelectGroupContainer .serverSideSelectInputGroup {
    background-color: hsl(0, 0%, 95%);
  }

  &.disabled .serverSideSelectGroupContainer .serverSideSelectInputGroup .input {
    color: grey;
  }

  &.disabled .serverSideSelectGroupContainer .serverSideSelectInputGroup * {
    cursor: not-allowed;
  }

  .menuContainer {
    box-sizing: border-box;
    position: absolute;
    z-index: 99999;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    background: #fafafa;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 .25rem .25rem;

    .itemsContainer {
      overflow: auto;
      max-height: 200px;
    }

    .menuItem {
      padding: 12px 7px;
      border-bottom: 1px solid #eaeaea;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background: #e0ecfc;
      }

      &.focused {
        color: white;
        background: #2584ff;
      }

      &.disabled {
        cursor: default;
        color: gray;

        &:hover, *.focused {
          background-color: transparent;
        }
      }
    }

    .paginationContainer {
      .navigation {
        font-size: 20px;
        padding: 3px 10px;
        cursor: pointer;

        &.disabled {
          &, & > * {
            cursor: not-allowed;
            color: grey;
          }
        }
      }

      .pageInfo {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
