.Table {
  .react-bootstrap-table, table {
    table-layout: auto;
    overflow-x: visible;
    overflow-y: visible !important;
  }

  #search-bar-0 {
    width: 200px;
  }

  .pagination {
    float: right;

    .page-item {
      .page-link {
        height: 29.96px;
        color: #d87548;
        padding: 0.25rem 0.5rem;
        line-height: 1.5;
        border-color: #d87548;
      }

      &.active {
        .page-link {
          background-color: #d87548 !important;
          color: white;
        }
      }
    }
  }

  .sortable {
    cursor: pointer;
  }

  .topbar-container {
    label {
      margin-bottom: 0;
    }

    input,
    button {
      min-height: 100% !important;
    }
  }

  th {
    border-top: none !important;
    border-bottom: none !important;
  }

  td {
    border-top: none !important;
    vertical-align: middle !important;
  }

  td:first-child {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  td:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .table thead th {
    color: #611b79db;
    font-size: 14.4px;
    font-weight: 600;
  }

  .bg-gray, .bg-gray:hover {
    background-color: rgba(0, 0, 0, .05) !important;
  }

  .expandable-row.expanded + tr {
    margin-bottom: 10px;
    overflow-x: auto;
  }

  .expandable-content-container {
    overflow-x: auto;
  }

  tbody table thead tr {
    background-color: white !important;
  }
}
