.General {
  .subject-error {
    position: relative;
    bottom: 14px;
  }
}

.CustomExtensions {
  #custom-extensions-button {
    margin-top: 28px;
  }

  #custom-extensions-output {
    th:nth-child(3), tr td:nth-child(3) {
      width: 50%;
      max-width: 100px;
    }

    tr td:nth-child(3) {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    tr td:nth-child(5) {
      text-align: right;
    }
  }
}

.CertificatePolicies {
  #certificate-policies-button {
    margin-top: 28px;
  }
}

.role-policy-table table tbody tr td {
  vertical-align: middle;
}
