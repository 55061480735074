.manufacturing-status-monitoring {
  .graph-widget {
    .loading-graph {
      display: block;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      bottom: 0;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(1px);
      z-index: 9;
    }
  }

  .label-left {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    label {
      white-space: nowrap;
      margin: 0 5px 0 0;
    }
  }
}
