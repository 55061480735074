.Trees {
  .Select {
    select {
      height: 38px;
    }
  }

  .search-bar {
    .counter { 
      position: relative;
      right: 115px;
      top: 1px;
    }
  }

  .rst__tree {
    right: 43px;
  }

  .rst__row.rst__rowSearchMatch{
    outline: 0;
    padding: .25rem;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    border-radius: .25rem!important;
    &.rst__rowSearchFocus{
      box-shadow: 0 0 0 0.2rem rgba(255, 145, 0, 0.836);
    }
  }

   .rst__rowContents {
    border-bottom-left-radius: .25rem!important;
    border-top-left-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important;
    border-top-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
    border-top-left-radius: .25rem!important;
   }
   .rst__lineChildren::after,
   .rst__lineHalfHorizontalRight::before, .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after {
    background-color:#80808085 ;
   }

   
   .rst__lineBlock:nth-child(2) {
     margin-left: 44px;
   }

  .rst__node {
    .rst__lineBlock:nth-child(1) {
      display: none;
    }
  }

   .rst__row {
     cursor: pointer;
   }

    .rst__rowTitle {
      margin-left: 25px;
    }

   .rst__collapseButton, .rst__expandButton {
    left: 18px !important;
    z-index: 1 !important;
   }

}
