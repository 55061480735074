@import url(https://fonts.googleapis.com/css?family=Lato);

.PKIApp {
  font-family: 'Lato', sans-serif, 'Source Sans Pro';
  font-size: 14px;

  .popover {
    max-width: 500px;
  }

  .textarea,
  .form-control-plaintext {
    font-family: 'Lato', sans-serif, 'Source Sans Pro';
    font-size: 14px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-contex {
    cursor: context-menu;
  }

  code.black-code {
    color: #212529;
  }

  .not-allowed {
    color: #6c757db0;
    cursor: not-allowed;

    svg {
      color: #6c757db0;
      cursor: not-allowed;
    }
  }

  .white-space-pre-wrap {
    white-space: pre-wrap;
  }

  .card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }

  .pki-ico {
    color: #d87548;
    cursor: pointer;
  }

  .pki-label {
    color: #611b79db;
    font-size: 14.4px;
    font-weight: 600;
  }

  .pki-header {
    color: #611b79db;
    font-size: 16.4px;
    font-weight: 600;
  }

  .invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  // react-select overrides

  .invalid-input {
    .css-yk16xz-control {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-color: #dc3545;
    }
  }

  .css-1pahdxg-control,
  .css-1pahdxg-control:focus-within,
  .css-1pahdxg-control:focus,
  .css-1pahdxg-control:hover,
  .css-1pahdxg-control:active {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .select-height {
    height: 38px;
  }

  // Bootstrap overrides

  //nav-tabs
  .nav-tabs {
    .nav-link {
      color: #611b79db;
      font-size: 14.4px;
      font-weight: 600;

      &.active {
        color: #d87548;
      }
    }
  }

  //dropdown
  .show > .btn-outline-secondary.dropdown-toggle {
    background-color: #d87548;
    border-color: #d87548;
  }

  .dropdown-item:active {
    background-color: #d87548;
  }

  //button-outline
  .btn-outline-secondary {
    color: #d87548;
    border-color: #d87548;

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 64, 104, 0.23);
    }

    &:not(:disabled):not(.disabled):active,
    &:hover {
      background-color: #d87548;
      color: #fff !important;
      border-color: #d87548;
    }

    &:disabled {
      color: #d87548;
      cursor: not-allowed;

      &:hover {
        color: #fff !important;
      }
    }
  }

  .textCopyPaste {
    width: 150px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }

  .visually-hidden {
    visibility: hidden !important;
  }

  .badge {
    color: #FFF;
  }

  .custom-control-input {
    position: absolute !important;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  .input-group .input-group-text:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none !important;
  }

  .accordion .accordion-item:not(:last-of-type) {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 20px;
  }

  .accordion .accordion-item .accordion-header > button.accordion-button {
    border: none;
    position: relative;
    background: transparent;
    width: 100%;
    text-align: left;
  }

  .accordion .accordion-item .accordion-body {
    padding: 7px;
  }


  button.accordion-button::after {
    content: "Collapse";
    position: absolute;
    transition: .2s background-color, border ease;
    height: 32px;
    right: 0;
    top: 0;
    font-size: 14px;
    line-height: 16px;
    border-radius: 5px;
    color: #d87548;
    border: 1px solid #d87548;
    padding: 5px 7px;
  }

  button.accordion-button.collapsed::after {
    content: "Expand";
  }

  .accordion .accordion-item .accordion-header:hover > button.accordion-button::after {
    background-color: #d87548;
    color: #fff !important;
    border-color: #d87548;
  }

  .purple-text {
    color: #611b79db !important;
  }

  .overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .form-control::placeholder {
    color: #bcbcbc;
  }

  .visually-hidden {
    visibility: hidden !important;
  }

  .line-through {
    text-decoration: line-through;
  }
}

[readonly] {
  outline: none !important;
  border: none !important;
}

.custom-control-label:after, .custom-control-label:before {
  pointer-events: none;
}
