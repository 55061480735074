.Notification {
  font-size: 16px;

  .Toastify__toast--error,
  .Toastify__close-button--error {
    color: #721c24db;
    background-color: #f4acb3;
    border-color: #f5c6cb;
  }

  .Toastify__progress-bar--error {
    background: #ff7f7f;
  }

  .Toastify__toast--warning {
    background: #f1c40ff0;
}
  
  .Toastify__close-button--success,
  .Toastify__toast--success {
    color: #155724d6;
    background-color: #bbf6c9;
    border-color: #c3e6cb;
  }
  .Toastify__progress-bar--success {
    background: #2daf4bbf;
  }
}
