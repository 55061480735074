.DeviceBatchRequests {
  .Table {
    .checkbox-label {
      margin-top: 2px;
    }

    th[aria-label="Quantity sortable"] {
      text-align: right;
    }

    .topbar-container {
      width: 100% !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #d87548;
      background-color: #d87548;
    }
  }
}
