#upload-asymmetric-keys-steps-nav {
  gap: 20px;
}

.upload-asymmetric-keys-step-nav {
  text-align: center;
  background-color: #e8e8e8;
  padding: 20px 15px;
  flex: 1;
  border-radius: 4px;
  cursor: default;

  &.active {
    background-color: #d87548;
    color: white;
    box-shadow: 0 2px 1px rgba(0, 0, 0, .2);

    .text-muted {
      color: white !important;
    }
  }
}
