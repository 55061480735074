.AuditLogs {
  .date-filter {
    .filter-button {
      height: 38px;
      margin-top: 29px;
    }
  }

  .Table .react-bootstrap-table.table-responsive {
    overflow: scroll;
  }
}
