.demo-product-profiles-provisioning {
  #add-demo-provisioning-profile {
    display: block;
    width: 100%;
  }
}

.demo-provisioning-profiles-table {
  padding-top: 15px;
  table thead th:last-of-type {
    width: 80px;
    text-align: center;
  }

  table tbody tr td:last-child {
    text-align: center;
  }
}
