.CheckboxList {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #d87548;
    background-color: #d87548;
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(216, 117, 72, 0.5) !important;
    color: #fff;
    border-color: #d87548;
  }
}
