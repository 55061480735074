.DatePicker {
  .date-value {
    width: 100%;
    height: 38px;
    padding: 6px;
    border-color: #cccccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .datetime-picker .calendar table thead th {
    color: #d87548;
  }
  .datetime-picker .calendar table tbody tr td.selected {
    background-color: #611b79db;
  }

  .datetime-picker .calendar table tbody tr td.now {
    color: #611b79db;
    &.selected {
      color: #ffff;
    }
  }
}
