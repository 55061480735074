.CertificateRequestForm {
  .Select {
    select {
      height: 38px;
    }
  }

  .tips-ico {
    margin-top: 13px !important;
  }
}
