.Layout {
  .main-layout-container {
    display: flex;
    flex: 1 1 0%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    flex-direction: column;
  }

  .fixed-container {
    position: relative;
  }

  .flex-container {
    flex: 1 1 0%;
    position: relative;
  }

  .sidebar {
    font-size: 0.815rem;
    font-weight: 600;
    color: #666;

    .menu-ico {
      text-align: center;
      font-size: 0.875rem;
    }

    .label {
      border-bottom: 1px solid #eee;
      background: #f7f7f7;
    }

    a {
      color: inherit; /* blue colors for links too */
      text-decoration: inherit; /* no underline */
    }

    li {
      color: #666;
      border: 0;
    }

    li.active {
      color: #00a3e0;
    }

    li.active::before {
      width: 5px;
      display: inline-block;
      height: 25px;
      background: #982974 !important;
      position: absolute;
      content: ' ';
      top: 5px;
      left: -6px;
      border-radius: 3px;
    }

    li:hover::before {
      width: 5px;
      display: inline-block;
      height: 25px;
      background: #d1d1d1;
      position: absolute;
      content: ' ';
      top: 5px;
      left: -6px;
      border-radius: 3px;
    }
  }
}
