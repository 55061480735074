.Navbar {
  .dropdown {
    right: 10px;
    top: 37px;
    width: 139px;
    overflow: auto;
    white-space: nowrap;
    background: #fbfbfb;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    font-size: 0.8rem;
    color: #666;
    z-index: 1;
  }

  .navbar {
    background-image: linear-gradient(
      to right,
      #341f69,
      #49236b,
      #5b286c,
      #6b2f6d,
      #79366e,
      #8c3b6d,
      #9d416c,
      #ac4969,
      #c05360,
      #cf6254,
      #d77548,
      #d98a3d
    );
  }
}
