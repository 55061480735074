.FilesTable-file-list {
  max-height: 300px;
  overflow-y: auto;

  .asymmetric-key-to-upload {
    background-color: #e8e8e8;
    border-radius: 4px;
    padding: 7px;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
